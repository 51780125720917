const chatTheme = {
    light: {
        primary: '#0063a3',
        primaryDark: '#0e416c',
        primaryText: '#ffffff',
        default: '#ffffff',
        defaultText: '#464b52',
        grayLight: '#f1f1f6',
        gray: '#e0e1e9',
        grayDark: '#7d808d',
        error: '#da212c',
        errorDark: '#ab1f26',
        fontFamily: `"Open Sans",sans-serif`
    },
    dark: {
        primary: '#0063a3',
        primaryDark: '#0e416c',
        primaryText: '#ffffff',
        default: '#353a40',
        defaultText: '#ffffff',
        grayLight: '#464b52',
        gray: '#e0e1e9',
        grayDark: '#7d808d',
        error: '#da212c',
        errorDark: '#ab1f26',
        fontFamily: `"Open Sans",sans-serif`
    }
}

const getChatConfig = () => {
    const {REACT_APP_PROD_URL, REACT_APP_LEGACY_PROD_URL} = process.env
    const envRegex = {
        pr: /^https?:\/\/[^/]+-pr\d+/,
        local: /^https?:\/\/localhost:3000/,
        localAlternate: /^https?:\/\/localhost:3001/,
        dev: /^https?:\/\/[^/]+-dev[^/]+\/?/,
        qa: /^https?:\/\/[^/]+-qa[^/]+\/?/,
        staging: /^https?:\/\/[^/]+-staging[^/]+\/?/
    }
    if (REACT_APP_PROD_URL) envRegex.prod = new RegExp(`^https?://(www\\.)?${REACT_APP_PROD_URL.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')}/?`)
    if (REACT_APP_LEGACY_PROD_URL) envRegex.legacyProd = new RegExp(`^https?://(www\\.)?${REACT_APP_LEGACY_PROD_URL.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')}/?`)

    const url = window.location.href

    const findKey = (obj, predicate = o => o) => Object.keys(obj)
        .find(key => predicate(obj[key], key, obj))

    let environment = findKey(envRegex, env => env.test(url)) || 'dev'
    if (environment === 'legacyProd') environment = 'prod'
    
    return {
        qa: 'https://chat-dev.instinct.trimble.com',
        local: 'https://chat-dev.instinct.trimble.com',
        localAlternate: 'https://chat-dev.instinct.trimble.com',
        staging: 'https://chat-stg.instinct.trimble.com'
    }[environment] || 'https://chat.instinct.trimble.com'
}

module.exports = {getChatConfig, chatTheme}
